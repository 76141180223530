<template>
  <section>
    <SunpodAddEdit @add-edit="handleRefresh" />
    <EdgeboxAddEdit @add-edit="handleRefresh" />

    <div v-if="!isLoaded" class="mt-2 h-96 bg-white rounded shadow vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>

    <template v-if="isLoaded">
      <section
        class="grid grid-cols-1 gap-y-2 lg:grid-cols-2 lg:gap-x-2 lg:gap-y-0"
      >
        <info-grid
          :title="$t('components.pods.sunpods.details.steps.profile.headline.basicInfo')"
          class="mt-2"
          grid-class="grid-cols-1"
          :data="getProfile"
          :editPermissionStatus="true"
          @edit="handleProfileEditing()"
        >
          <template v-slot:subheader>
            <ProfileStatusDropdown
              :status="getProfileStatus"
              :userId="id"
              @refresh="fetchData"
            />
          </template>
        </info-grid>

        <info-grid
          :title="$t('components.pods.sunpods.details.steps.profile.headline.edgebox')"
          class="mt-2"
          grid-class=""
          :data="getEdgeboxData"
          :editPermissionStatus="true"
          @edit="handleEdgeBoxEditing()"
        />
      </section>
      <section
        class="grid grid-cols-1 gap-y-2 lg:grid-cols-2 lg:gap-x-2 lg:gap-y-0 mt-2"
      >
        <info-grid
         :title="$t('components.pods.sunpods.details.steps.profile.headline.batterySummary')"
          class="mt-2"
          grid-class="grid-cols-1"
          :data="getBatterySummary"
        />

        <info-grid
           :title="$t('components.pods.sunpods.details.steps.profile.headline.solarChargerSummary')"
          class="mt-2"
          grid-class="grid-cols-1"
          :data="getSolarChargerSummary"
        />
      </section>
      <section class="grid grid-cols-1 gap-y-2 mt-2">
        <info-grid
          :title="$t('components.pods.sunpods.details.steps.profile.headline.batteryHistory')"
          class="mt-2"
          grid-class="grid-cols-2"
          :data="getBatteryHistory"
        />
      </section>
    </template>
  </section>
</template>

<script>
import { SunpodsConfig } from '@/config/SunpodsConfig'
import { EdgeboxConfig } from '@/config/EdgeboxConfig'
import InfoGrid from '@/components/layout/InfoGrid'
import SunpodAddEdit from '@/views/sunpods/SunpodAddEdit'
import EdgeboxAddEdit from '@/views/sunpods/EdgeboxAddEdit.vue'
import ProfileStatusDropdown from '@/views/sunpods/blocks/profile/ProfileStatusDropdown.vue'
import { EventBus } from '@/utils/EventBus'

export default {
  components: {
    InfoGrid,
    // ContentSection,
    SunpodAddEdit,
    EdgeboxAddEdit,
    ProfileStatusDropdown,
    // OtoTypography,
  },
  data() {
    return {
      isLoaded: false,
      fallbackText: '--',
      profile: {},
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },

    getProfile() {
      let data = []
      data.push({
           text: this.$t(
            'components.pods.sunpods.details.steps.profile.basicInfo.id'
          ),
        value: this.profile
          ? this.profile.sunpod_details.id
          : this.fallbackText,
      })
      data.push({
       text: this.$t(
            'components.pods.sunpods.details.steps.profile.basicInfo.name'
          ),
        value: this.profile
          ? this.profile.sunpod_details.name
          : this.fallbackText,
      })

      data.push({
       text: this.$t(
            'components.pods.sunpods.details.steps.profile.basicInfo.instance'
          ),
        value: this.profile
          ? this.profile.sunpod_details.instance
          : this.fallbackText,
      })
      data.push({
      text: this.$t(
            'components.pods.sunpods.details.steps.profile.basicInfo.name'
          ),
        value: this.profile
          ? this.profile.sunpod_details.name
          : this.fallbackText,
      })
      data.push({
         text: this.$t(
            'components.pods.sunpods.details.steps.profile.basicInfo.vrmUsername'
          ),
        value: this.profile
          ? this.profile.sunpod_details.vrm_user_email
          : this.fallbackText,
      })
      data.push({
        text: this.$t(
            'components.pods.sunpods.details.steps.profile.basicInfo.vrmPassword'
          ),
        value: this.profile
          ? this.profile.sunpod_details.vrm_password
          : this.fallbackText,
      })
      data.push({
       text: this.$t(
            'components.pods.sunpods.details.steps.profile.basicInfo.cameraIP'
          ),
        value: this.profile
          ? this.profile.sunpod_details.sunpod_camera_ip
          : this.fallbackText,
      })
      data.push({
        text: this.$t(
            'components.pods.sunpods.details.steps.profile.basicInfo.cameraRTSPUsername'
          ),
        value: this.profile
          ? this.profile.sunpod_details.sunpod_camera_rtsp_user
          : this.fallbackText,
      })
      data.push({
      text: this.$t(
            'components.pods.sunpods.details.steps.profile.basicInfo.cameraRTSPPassword'
          ),
        value: this.profile
          ? this.profile.sunpod_details.sunpod_camera_rtsp_password
          : this.fallbackText,
      })

      return data
    },
    getProfileStatus() {
      if (this.profile && this.profile.sunpod_details) {
        return this.profile.sunpod_details.is_active
      } else {
        return false
      }
    },

    getEdgeboxData() {
      let data = []

      data.push({
     text: this.$t(
            'components.pods.sunpods.details.steps.profile.edgebox.id'
          ),
        value: this.profile
          ? this.profile.sunpod_details.assigned_edgebox
            ? this.profile.sunpod_details.assigned_edgebox.id
            : this.fallbackText
          : this.fallbackText,
      })

      data.push({
        text: this.$t(
            'components.pods.sunpods.details.steps.profile.edgebox.name'
          ),
        value: this.profile
          ? this.profile.sunpod_details.assigned_edgebox
            ? this.profile.sunpod_details.assigned_edgebox.name
            : this.fallbackText
          : this.fallbackText,
      })

      data.push({
      text: this.$t(
            'components.pods.sunpods.details.steps.profile.edgebox.imei'
          ),
        value: this.profile
          ? this.profile.sunpod_details.assigned_edgebox
            ? this.profile.sunpod_details.assigned_edgebox.imei
            : this.fallbackText
          : this.fallbackText,
      })

      data.push({
        text: this.$t(
            'components.pods.sunpods.details.steps.profile.edgebox.macAddress'
          ),
        value: this.profile
          ? this.profile.sunpod_details.assigned_edgebox
            ? this.profile.sunpod_details.assigned_edgebox.mac_address
            : this.fallbackText
          : this.fallbackText,
      })
      data.push({
       text: this.$t(
            'components.pods.sunpods.details.steps.profile.edgebox.doorStatus'
          ),
        value: this.profile
          ? this.profile.sunpod_details.assigned_edgebox
            ? this.profile.sunpod_details.assigned_edgebox.is_door_open
              ? 'Opened'
              : 'Closed'
            : this.fallbackText
          : this.fallbackText,
      })

      data.push({
        text: this.$t(
            'components.pods.sunpods.details.steps.profile.edgebox.lightStatus'
          ),
        value: this.profile
          ? this.profile.sunpod_details.assigned_edgebox
            ? this.profile.sunpod_details.assigned_edgebox.is_light_on
              ? 'On'
              : 'Off'
            : this.fallbackText
          : this.fallbackText,
      })
      return data
    },

    getBatterySummary() {
      let data = []
      data.push({
        text: this.$t(
            'components.pods.sunpods.details.steps.profile.batterySummary.voltage'
          ),
        value: this.profile
          ? this.profile.battery_summary.voltage
          : this.fallbackText,
        color: this.getPrimitiveColor(
          this.profile.battery_summary.voltage || ''
        ),
      })

      data.push({
         text: this.$t(
            'components.pods.sunpods.details.steps.profile.batterySummary.current'
          ),
        value: this.profile
          ? this.profile.battery_summary.current
          : this.fallbackText,
        color: this.getPrimitiveColor(
          this.profile.battery_summary.current || ''
        ),
      })

      data.push({
          text: this.$t(
            'components.pods.sunpods.details.steps.profile.batterySummary.power'
          ),
        value: this.profile
          ? this.profile.battery_summary.power
          : this.fallbackText,
        color: this.getPrimitiveColor(this.profile.battery_summary.power || ''),
      })

      data.push({
         text: this.$t(
            'components.pods.sunpods.details.steps.profile.batterySummary.consumedAmphours'
          ),
        value: this.profile
          ? this.profile.battery_summary.consumed_amphours
          : this.fallbackText,
        color: this.getPrimitiveColor(
          this.profile.battery_summary.consumed_amphours || ''
        ),
      })
      data.push({
    text: this.$t(
            'components.pods.sunpods.details.steps.profile.batterySummary.stateOfCharge'
          ),
        value: this.profile
          ? this.profile.battery_summary.state_of_charge
          : this.fallbackText,
        color: this.getPrimitiveColor(
          this.profile.battery_summary.state_of_charge || ''
        ),
      })
      return data
    },
    getSolarChargerSummary() {
      let data = []
      data.push({
       text: this.$t(
            'components.pods.sunpods.details.steps.profile.solarChargerSummary.batteryWatts'
          ),
        value: this.profile
          ? this.profile.solar_charger_summary.battery_watts
          : this.fallbackText,
        color: this.getPrimitiveColor(
          this.profile.solar_charger_summary.battery_watts || ''
        ),
      })
      data.push({
         text: this.$t(
            'components.pods.sunpods.details.steps.profile.solarChargerSummary.chargerState'
          ),
        value: this.profile
          ? this.profile.solar_charger_summary.charge_state
          : this.fallbackText,
      })
      data.push({
       text: this.$t(
            'components.pods.sunpods.details.steps.profile.solarChargerSummary.voltage'
          ),
        value: this.profile
          ? this.profile.solar_charger_summary.voltage
          : this.fallbackText,
        color: this.getPrimitiveColor(
          this.profile.solar_charger_summary.voltage || ''
        ),
      })
      data.push({
         text: this.$t(
            'components.pods.sunpods.details.steps.profile.solarChargerSummary.yieldToday'
          ),
        value: this.profile
          ? this.profile.solar_charger_summary.yield_today
          : this.fallbackText,
        color: this.getPrimitiveColor(
          this.profile.solar_charger_summary.yield_today || ''
        ),
      })
      data.push({
        text: this.$t(
            'components.pods.sunpods.details.steps.profile.solarChargerSummary.yieldYesterday'
          ),
        value: this.profile
          ? this.profile.solar_charger_summary.yield_yesterday
          : this.fallbackText,
        color: this.getPrimitiveColor(
          this.profile.solar_charger_summary.yield_yesterday || ''
        ),
      })
      data.push({
     text: this.$t(
            'components.pods.sunpods.details.steps.profile.solarChargerSummary.relayOnTheCharger'
          ),
        value: this.profile
          ? this.profile.solar_charger_summary.relay_on_the_charger
          : this.fallbackText,
      })
      return data
    },
    getBatteryHistory() {
      let data = []
      data.push({
        text: this.$t(
            'components.pods.sunpods.details.steps.profile.batteryHistory.deepestDischarge'
          ),
        value: this.profile
          ? this.profile.battery_history.deepest_discharge
          : this.fallbackText,
        color: this.getPrimitiveColor(
          this.profile.battery_history.deepest_discharge || ''
        ),
      })

      data.push({
         text: this.$t(
            'components.pods.sunpods.details.steps.profile.batteryHistory.lastDischarge'
          ),
        value: this.profile
          ? this.profile.battery_history.last_discharge
          : this.fallbackText,
        color: this.getPrimitiveColor(
          this.profile.battery_history.last_discharge || ''
        ),
      })

      data.push({
       text: this.$t(
            'components.pods.sunpods.details.steps.profile.batteryHistory.averageDischarge'
          ),
        value: this.profile
          ? this.profile.battery_history.average_discharge
          : this.fallbackText,
        color: this.getPrimitiveColor(
          this.profile.battery_history.average_discharge || ''
        ),
      })

      data.push({
      text: this.$t(
            'components.pods.sunpods.details.steps.profile.batteryHistory.chargeCycles'
          ),
        value: this.profile
          ? this.profile.battery_history.charge_cycles
          : this.fallbackText,
        color: this.getPrimitiveColor(
          this.profile.battery_history.charge_cycles || ''
        ),
      })

      data.push({
        text: this.$t(
            'components.pods.sunpods.details.steps.profile.batteryHistory.fullDischarge'
          ),
        value: this.profile
          ? this.profile.battery_history.full_discharges
          : this.fallbackText,
        color: this.getPrimitiveColor(
          this.profile.battery_history.full_discharges || ''
        ),
      })

      data.push({
       text: this.$t(
            'components.pods.sunpods.details.steps.profile.batteryHistory.totalAhDrawn'
          ),
        value: this.profile
          ? this.profile.battery_history.total_ah_drawn
          : this.fallbackText,
        color: this.getPrimitiveColor(
          this.profile.battery_history.total_ah_drawn || ''
        ),
      })

      data.push({
        text: this.$t(
            'components.pods.sunpods.details.steps.profile.batteryHistory.minimumVoltage'
          ),
        value: this.profile
          ? this.profile.battery_history.minimum_voltage
          : this.fallbackText,
        color: this.getPrimitiveColor(
          this.profile.battery_history.minimum_voltage || ''
        ),
      })
      data.push({
        text: this.$t(
            'components.pods.sunpods.details.steps.profile.batteryHistory.maximumVoltage'
          ),
        value: this.profile
          ? this.profile.battery_history.maximum_voltage
          : this.fallbackText,
        color: this.getPrimitiveColor(
          this.profile.battery_history.maximum_voltage || ''
        ),
      })
      data.push({
        text: this.$t(
            'components.pods.sunpods.details.steps.profile.batteryHistory.timeSinceLastFullCharge'
          ),
        value: this.profile
          ? this.profile.battery_history.time_since_last_full_charge
          : this.fallbackText,
        color: this.getPrimitiveColor(
          this.profile.battery_history.time_since_last_full_charge || ''
        ),
      })

      data.push({
      text: this.$t(
            'components.pods.sunpods.details.steps.profile.batteryHistory.automaticSyncs'
          ),
        value: this.profile
          ? this.profile.battery_history.automatic_syncs
          : this.fallbackText,
        color: this.getPrimitiveColor(
          this.profile.battery_history.automatic_syncs || ''
        ),
      })

      data.push({
        text: this.$t(
            'components.pods.sunpods.details.steps.profile.batteryHistory.lowVoltageAlarms'
          ),
        value: this.profile
          ? this.profile.battery_history.low_voltage_alarms
          : this.fallbackText,
        color: this.getPrimitiveColor(
          this.profile.battery_history.low_voltage_alarms || ''
        ),
      })

      data.push({
         text: this.$t(
            'components.pods.sunpods.details.steps.profile.batteryHistory.highVoltageAlarms'
          ),
        value: this.profile
          ? this.profile.battery_history.high_voltage_alarms
          : this.fallbackText,
        color: this.getPrimitiveColor(
          this.profile.battery_history.high_voltage_alarms || ''
        ),
      })

      data.push({
       text: this.$t(
            'components.pods.sunpods.details.steps.profile.batteryHistory.dischargedEnergy'
          ),
        value: this.profile
          ? this.profile.battery_history.discharged_energy
          : this.fallbackText,
        color: this.getPrimitiveColor(
          this.profile.battery_history.discharged_energy || ''
        ),
      })
      data.push({
        text: this.$t(
            'components.pods.sunpods.details.steps.profile.batteryHistory.chargedEnergy'
          ),
        value: this.profile
          ? this.profile.battery_history.charged_energy
          : this.fallbackText,
        color: this.getPrimitiveColor(
          this.profile.battery_history.charged_energy || ''
        ),
      })
      return data
    },
  },
  async created() {
    this.fetchData()
  },
  mounted() {
    EventBus.$on(SunpodsConfig.events.refresh, () => {
      console.log('SunpodsConfig-refresh')
      this.fetchData()
    })
  },
  methods: {
    fetchData() {
      this.$http
        .get(SunpodsConfig.api.detail(this.id))
        .then((res) => {
          console.log('view-profile', res.data)
          this.profile = { ...res.data }
          this.isLoaded = true
        })
        .catch((err) => {
          console.log('view-profil-err', err)
        })
    },

    showModal() {
      dispatchEvent(new Event(SunpodsConfig.events.sorToggle))
      return
    },

    handleProfileEditing() {
      const item = {
        id: this.profile.sunpod_details.id,
        name: this.profile.sunpod_details.name,
        instance: this.profile.sunpod_details.instance,
        assigned_edgebox: this.profile.sunpod_details.assigned_edgebox.id,
        vrm_user_email: this.profile.sunpod_details.vrm_user_email,
        vrm_password: this.profile.sunpod_details.vrm_password,
        is_active: this.profile.sunpod_details.is_active,
      }

      EventBus.$emit(SunpodsConfig.events.editingData, item)
      dispatchEvent(new Event(SunpodsConfig.events.sorToggle))
    },
    handleEdgeBoxEditing() {
      if (!this.profile.sunpod_details.assigned_edgebox) {
        this.$notify(
          {
            group: 'generic',
            type: 'danger',
            title: 'Failed',
             text: this.$t(
            'components.pods.sunpods.details.steps.profile.handleEdgeBoxEditing'
          ),
          },
          3000
        )
        return
      }
      const item = {
        id: this.profile.sunpod_details.assigned_edgebox.id,
        name: this.profile.sunpod_details.assigned_edgebox.name,
        imei: this.profile.sunpod_details.assigned_edgebox.imei,
        mac_address: this.profile.sunpod_details.assigned_edgebox.mac_address,
      }

      EventBus.$emit(EdgeboxConfig.events.editingData, item)
      dispatchEvent(new Event(EdgeboxConfig.events.sorToggle))
    },
    getPrimitiveColor(valueString) {
      if (valueString && valueString.split(' ').length) {
        if (Number(valueString.split(' ')[0]) < 0) return 'red'
        else if (Number(valueString.split(' ')[0]) === 0) return 'orange'
        else return ''
      } else {
        return ''
      }
    },
    handleRefresh() {
      this.isLoaded = false
      this.fetchData()
    },
  },
}
</script>

<style lang="scss">
.edit-button {
  width: 96px;
  height: 42px;
  // margin: 0 0 17px 19px;
  padding: 4px 23px 5px 12px;
  border-radius: 30px;
  background-color: #f3f3f3;
}
</style>
