var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"grid"},[_c('div',{staticClass:"grid grid-cols-2 my-3"},[_c('div',{staticClass:"font-semibold text-14px text-oDark"},[_vm._v(" "+_vm._s(_vm.$t('components.pods.sunpods.details.steps.profile.basicInfo.profileStatus'))+" ")]),_c('div',{staticClass:"font-medium text-gray-500 text-14px"},[_c('t-dropdown',{attrs:{"variant":"smActionsLeft"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
            var mousedownHandler = ref.mousedownHandler;
            var focusHandler = ref.focusHandler;
            var blurHandler = ref.blurHandler;
            var keydownHandler = ref.keydownHandler;
return [_c('button',{staticClass:"profile-status-badge focus:outline-none",class:_vm.currentStatus ? 'active' : 'deactive',attrs:{"aria-label":"Profile Status","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_vm._v(" "+_vm._s(_vm.getCurrentStatusText)+" "),_c('i',{staticClass:"mt-1 ml-1 fas fa-chevron-down"})])]}},{key:"default",fn:function(ref){
            var hide = ref.hide;
return [_c('div',{staticClass:"py-1 mt-px bg-white rounded-md shadow-md",on:{"click":function($event){return hide()}}},[(_vm.currentStatus === true)?_c('t-dropdown-item',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.action(false)}}},[_vm._v(" Deactivate ")]):_vm._e(),(_vm.currentStatus === false)?_c('t-dropdown-item',{on:{"click":function($event){return _vm.action(true)}}},[_vm._v(" Activate ")]):_vm._e()],1)]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }