<template>
  <div>
    <div class="grid">
      <div class="grid grid-cols-2 my-3">
        <div class="font-semibold text-14px text-oDark">
        {{ $t('components.pods.sunpods.details.steps.profile.basicInfo.profileStatus') }}
        </div>
        <div class="font-medium text-gray-500 text-14px">
          <t-dropdown variant="smActionsLeft">
            <template
              v-slot:trigger="{
                mousedownHandler,
                focusHandler,
                blurHandler,
                keydownHandler,
              }"
            >
              <button
                class="profile-status-badge focus:outline-none"
                :class="currentStatus ? 'active' : 'deactive'"
                aria-label="Profile Status"
                aria-haspopup="true"
                @mousedown="mousedownHandler"
                @focus="focusHandler"
                @blur="blurHandler"
                @keydown="keydownHandler"
              >
                {{ getCurrentStatusText }}
                <i class="mt-1 ml-1 fas fa-chevron-down" />
              </button>
            </template>
            <template v-slot:default="{ hide }">
              <div
                @click="hide()"
                class="py-1 mt-px bg-white rounded-md shadow-md"
              >
                <t-dropdown-item
                  v-if="currentStatus === true"
                  @click="action(false)"
                  variant="danger"
                >
                  Deactivate
                </t-dropdown-item>
                <t-dropdown-item
                  v-if="currentStatus === false"
                  @click="action(true)"
                >
                  Activate
                </t-dropdown-item>
              </div>
            </template>
          </t-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TDropdownItem from '@/components/dropdown/TDropdownItem'
import { SunpodsConfig } from '@/config/SunpodsConfig'

export default {
  name: 'ProfileStatusDropdown',
  components: {
    TDropdownItem,
  },
  props: {
    status: {
      type: Boolean,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },
  watch: {
    status(updated) {
      this.currentStatus = updated
    },
  },
  data() {
    return {
      isLoading: false,
      currentStatus: this.status,
    }
  },
  computed: {
    getCurrentStatusText() {
      return this.currentStatus ? 'Active' : 'Deactivate'
    },
  },
  methods: {
    id() {
      return this.$route.params.id
    },

    async action(status) {
      if (this.$acl.canNotUpdate('Sun Pod Areas')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'update',
            permission: 'Sun Pod Areas',
          })
        )
        return
      }
      this.isLoading = true

      const formData = new FormData()
      formData.append('is_active', status)

      await this.$http
        .patch(SunpodsConfig.api.update(this.userId), formData)
        .then((res) => {
          console.log('SunpodsConfig', res)
          this.$emit('refresh')
        })
        .catch((err) => {
          console.warn(err)
          this.$notify(
            {
              group: 'generic',
              type: 'danger',
              title: 'Action Execution Failed!',
              text: `The action encountered an error from server. [ERR:${err.response.status}]`,
            },
            5000
          )
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.profile-status-badge {
  width: 6rem;
  @apply rounded-full px-1 py-0;

  &.active {
    @apply bg-green-200 text-oGreen;
  }
  &.deactive {
    @apply bg-red-200 text-oRed;
  }
}
</style>
